import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

export default function ServicesAndRates() {
  return (
    <Layout>
      <ServicesAndRatesContent></ServicesAndRatesContent>
    </Layout>
  )
}

const basic = [
  'One 700+ word blog post',
  'Keyword implementation',
  'One royalty free image',
  'List of sources'
];

const standard = [
  'One 700+ word blog post',
  '4 social media graphics',
  'Caption creation',
  'Hashtag implementation'
];

const standardPlus = [
  '12 posts per month',
  'Your choice of platforms',
  'Caption creation',
  'Hashtag implementation'
]

const topics = [
  'Diet, lifestyle, and self-care',
  'Hashimoto\'s disease and thyroid disorders',
  'Lyme disease',
  'IBS and gut disorders',
  'Nutrition',
  'Juicing and smoothies',
  'Stress reduction',
  'Anxiety',
  'Plant-based and vegan diets',
  'Autoimmune-friendly foods (and AIP diets)',
  'Yoga, meditation, and mindfulness',
  'Non-toxic cosmetics and personal care products',
  'Cruelty-free living',
  'Detoxification',
  'General health and wellness tips',
  'Whatever you need from us!',
  'Gut health',
  'Probiotics + gut microbiota',
  'Women\'s health',
  'Perimenopause',
  'Menopause',
  'Supplements',
  'Autoimmunity',
  'Functional medicine',
  'Naturopathic medicine',
  'Holistic health',
];

export function ServicesAndRatesContent() {
  return (
    <section className="m-y-3">
      <div className="flex">
        <section className="bordered top p-2 m-a">
          <h1 className="text-center">BLOGGING SERVICES & RATES</h1>
        </section>
      </div>

      <div className="flex justify-content-center-space-between m-t-3 thirds">
        <section className="text-center">
          <img src="/assets/images/service-1.1.jpg"></img>
          <h3 className="m-y-1">Basic Blogging: $150</h3>
          <ul>
            {basic.map(item => <li>{item}</li>)}
          </ul>
        </section>

        <section className="text-center">
          <img src="/assets/images/service-2.1.jpg"></img>
          <h3 className="m-y-1">Blogging + Social Media: $280</h3>
          <ul>
            {standard.map(item => <li>{item}</li>)}
          </ul>
        </section>

        <section className="text-center">
          <img src="/assets/images/service-3.1.jpg"></img>
          <h3 className="m-y-1">Social Media + Pinterest: $375</h3>
          <ul>
            {standardPlus.map(item => <li>{item}</li>)}
          </ul>
        </section>
      </div>

      <div className="text-center"><p><strong>Custom packages and services are available upon request. <Link to="/contact">Contact us</Link> to create a solution that works for you! </strong></p></div>

      <section className="bordered bottom full-width p-1 m-t-1">
        <div className="flex justify-content-center-space-between">
          <div className="m-r-2">
            <h3 className="m-b-1">Additional Marketing Services</h3>
            <p>We also offer these additional marketing services:</p>
            <ul className="bullet">
              <li><strong>Social media:</strong> Instagram and Facebook graphics, captions, hashtags, and account audits</li>
              <li><strong>Pinterest:</strong> account management, pin creation, captions, and account audits</li>
              <li><strong>Content creation:</strong> digital design, logos, video creation, YouTube, TikTok, business marketing materials, slide decks, presentations, handouts, guides, client freebies, and worksheets</li>
              <li><strong>SEO:</strong> monthly monitoring, SEO audits, and website content optimization</li>
              <li><strong>Email campaigns:</strong> email design, monthly newsletters, and email management</li>
              <li><strong>Writing and proofreading:</strong> website content, podcast scripts, workshops, courses, editing, and ghostwriting</li>
            </ul>
            <p>We charge an hourly rate for the content creation, writing, and proofreading services listed above. We are happy to provide an estimate upon discussing the details of your project.</p>
          </div>
          <div className="m-l-2">
            <h3 className="m-b-1">Still Undecided?</h3>
            <ul>
              <li className="m-b-1">Let us start by writing a paid 700-word sample article for your blog ($100).</li>
              {/* <li className="m-b-1"><a href="/assets/Well From Within Services & Rates 2023.pdf" target="_blank">Click here</a> to view my 2023 Services & Rates.</li> */}
              <li><Link to="/contact">Contact us</Link> with your chosen blog topic and any keywords you’d like to include in the article.</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="flex justify-content-center-space-between align-items-center halves m-y-2">
        <section className="bordered left p-2">
          <h1 className="m-b-2">Topics We Write About</h1>

          <h3 className="m-t-2">A few of the topics we write about include:</h3>
          <ul className="bullet text-md m-y-1">
            {topics.map(item => <li>{item}</li>)}
          </ul>

        </section>

        <img src="/assets/images/service-4.1.jpg"></img>
      </section>

    </section>
  )
}
